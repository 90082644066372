import React from "react"
import Image from "../image"
import Paper from '@material-ui/core/Paper';

import "./Header.css"

const Header = _ => (
  <header>
    <div className="logoAppsflow" >
      <Image name="appsbrokerLogo" />
    </div>
    <Paper className="logosContainer">
      <Image name="logoUe" />
      <Image name="logoGuvernRo" />
      <Image name="logoIs" />
    </Paper>    
  </header>
)

export default Header
