import React from "react"
import "./Footer.css"

const Footer = _ => (
  <footer>
    <div className="copyright">
      © {new Date().getFullYear()} - Conţinutul acestui material nu reprezintă în mod obligatoriu poziţia oficială a Uniunii Europene sau a Guvernului României
    </div> 
  </footer>
)

export default Footer
