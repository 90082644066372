import React from 'react'
import Helmet from 'react-helmet'
import Layout from '../components/Layout'
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Image from '../components/image';
import AbAccordion from "../components/Accordion"

const IndexPage = () => (
  <Layout>
    <Helmet title="Appsflow from Appsbroker">
      <link
        rel="stylesheet"
        href="https://fonts.googleapis.com/css?family=Roboto:300,400,500,700, 800&display=swap"
      />
      <link
        rel="stylesheet"
        href="https://fonts.googleapis.com/icon?family=Material+Icons"
      />
      <meta name="robots" content="noindex" />
    </Helmet>

    <Paper className="funds">
      <p>
        Proiectul APPSFLOW – Dezvoltarea SAAS a sistemului de aplicații
        configurabile de procese de business ce accelerează inițiativele de
        lucru inteligent în organizații este finanțat prin Programul Operațional
        Competitivitate, 2014-2020, Axa Prioritară 2 "Tehnologia Informației și
        Comunicațiilor (TIC) pentru o economie digitală competitivă", Obiectiv
        specific 2.2. „Creșterea contribuției sectorului TIC pentru
        competitivitatea economică”, Acțiunea 2.2.1, „Sprijinirea creșterii
        valorii adăugate generate de sectorul TIC și a inovării în domeniu prin
        dezvoltarea de clustere”, Prioritate de investiții 2b.
      </p>
      <p>
        Pentru informaţii detaliate despre celelalte programe cofinanţate de
        Uniunea Europeană, vă invităm să vizitaţi{" "}
        <a href="http://www.fonduri-ue.ro/">www.fonduri-ue.ro</a>
      </p>
    </Paper>

    <h1>Descriere</h1>
    <Grid container spacing={4}>
      <Grid
        item
        xs={12}
        sm={12}
        md={6}
        lg={6}
        container
        justifyContent="center"
      >
        <Grid item xs={12} sm={6} md={6} lg={6} container alignItems="center">
          <Image className="padded" name="projectLogo" />
        </Grid>
        <p>
          Appsflow este o soluție SaaS care permite experților pe acest produs,
          instruiți, sa construiască rapid aplicații web de nivel enterprise cu
          un focus pe procese de afaceri. Soluția SaaS cu instrumentele Appsflow
          permit experților pe produs să configureze si să implementeze
          aplicații web într-o zecime din timpul unei dezvoltări de aplicații in
          mod tradițional.
        </p>
        <p>
          Pentru a susține aceasta informație, unul din indicatorii acestui
          proiect este ca de la 10 clienți ai viitorului serviciu SaaS Appsflow,
          sa primim cel puțin 4 studii de caz care vor fi furnizate sa
          demonstreze o rata de 30% de economisire a costurilor de creare a unei
          aplicații cerute de client folosind Appsflow în comparație cu
          folosirea tehnologiilor tradiționale, care nu sunt bazate pe servicii
          cloud.
        </p>
        <p>
          Smarter Worker powered by Appsflow' este o inițiativă dezvoltată de
          Appsbroker ce va reuni instrumente cheie, software pentru a permite
          organizațiilor sa transforme forța lor de muncă prin folosirea de
          tehnologii cloud și o mobilitate crescută.
        </p>
        <p>
          Appsflow oferă un set unic de caracteristici care se adresează
          necesitaților comune din organizații: formulare web și mobile, baze de
          date centralizate, automatizarea proceselor si fluxurilor de business,
          stocare și partajare, raportare, audit, notificări, implementări de
          aplicații prin intermediul unui portal central bazat pe carduri
          personalizate. Appsflow va fi construit pe platforma Cloud și prin
          urmare, beneficiază de scalabilitatea cloud, elasticitate, servicii
          disponibile la nivel global, de securitate și de modelul de calcul ca
          utilitate.
        </p>
        <p>
          Pentru a accesa Appsflow click <a href="/login">aici</a>
        </p>
      </Grid>
      <Grid item xs={12} sm={12} md={6} lg={6}>
        <Paper>
          <Image name="comunicat" />
        </Paper>
      </Grid>
    </Grid>

    <h1>Obiective</h1>
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <p>
          Pe scurt, obiectivul general al proiectului este de a dezvolta
          capacitățile noastre tehnice existente dobândite, pana acum, prin
          proiecte pilot (pana acum având un portofoliu complet integrat de
          activități robuste dar nescalabile), totul menit să evolueze
          aplicațiile noastre pilot într-un nou serviciu SaaS (software ca
          serviciu) extrem de robust și scalabil. Mărirea capacității noastre de
          a sprijini transformarea digitală în cadrul organizațiilor prin
          adoptarea tehnologiilor informaționale de ultimă oră si prin
          eliminarea blocajelor obișnuite din IT și constrângerile de cost
          tradiționale.
        </p>
        <p>
          Atât arhitectura de bază in cloud cat și proiectarea sistemului poate
          fi considerată ca fiind extrem de inovatoare. In timp ce căutăm a
          valorifica tehnologie avansata in cloud / cu cost redus, în același
          timp construim o interfața care ascunde aceasta tehnologie și
          software-ul de bază care stau la baza aplicației în timp ce oferă o
          metodă flexibilă de punere rapida în aplicare a logicii de business,
          fără cunoștințe tehnice profunde.
        </p>
        <p>
          Pentru a atinge rezultatul dorit o serie de obiective specifice sunt
          țintite de proiect in scopul de a realiza livrarea capacitățile
          tehnologice descrise.
        </p>
      </Grid>
    </Grid>

    <AbAccordion
      entries={[
        {
          heading: "Obiectiv 1",
          content:
            "Primul obiectiv este de a sprijini transformarea digitală și modernizarea sistemelor existente in companii  prin furnizarea de capabilități de afaceri sofisticate și foarte flexibile bazate pe tehnologii cloud, la un preț mai mic, pentru a crește productivitatea muncii și reducerea costurilor prin stabilirea unei capacități cloud care elimină dependența de sistemele vechi IT (aceste costuri includ costurile de licență software, hardware local, costuri de găzduire și suport / forța de muncă de întreținere sau costuri de contractare). În plus, moștenirea IT clasica din majoritatea companiilor, care nu oferă acces ușor pentru utilizatorii din afara rețelei lor de computere, coroborat cu noile modele de afaceri schimbate (creșterea  utilizării intense de parteneri, furnizori sau externalizare) face ca flexibilitatea sa fie cheia pentru ca organizațiile să rămână competitive. În plus, prevalența sistemelor inflexibile existente și costisitoare a dus la procese de business manuale sau‚  shadow IT’ care sunt dependente de activități care necesită resurse multe cu risc de erori ce determina venituri pierdute sau o reducere a marjelor de profit.  Crearea de soluții tehnologice pentru cele descrise mai sus, este de multe ori prohibitiv pentru organizații, si anume sa livreze tehnologie moderna pentru a permite automatizarea proceselor de business, fluxurilor de lucru, implicarea angajaților și furnizarea de informații pertinente pentru utilizatorii finali in mod continuu.",
        },
        {
          heading: "Obiectiv 2",
          content:
            "Al doilea obiectiv este creșterea calității si performanțelor unui produs SaaS prin includerea celor mai noi tehnologii, prin cercetare-dezvoltare. Se urmărește stabilizarea unui Software Pilot & evoluția către un produs de tip SaaS in cloud. Acest obiectiv se concentrează asupra desfășurării cercetării și dezvoltării pentru a crea o platformă SaaS foarte robusta de nivel enterprise . Construirea pe seama succesului inițial al pilotului de a realiza acest obiectiv, echipa tehnică va trebui să cerceteze, proiecteze și sa dezvolte o serie de capabilități pentru a cuprinde funcționalitatea creata atât în pilot cat și susținerea obiectivului de a avea o platforma SaaS foarte robusta, flexibilă și scalabilă in cloud, care va permite adoptarea rapidă a tehnologiei.",
        },
        {
          heading: "Obiectiv 3",
          content:
            "Al treilea obiectiv al programului este evoluția și extinderea capabilității existente de dezvoltare pe platforma de cloud, de testare, operațiunile și mentenanța din sediul din Iași. Pentru a realiza acest lucru personalul tehnic, operațional și administrativ nou va trebui să fie recrutat și format. În plus, se va face transfer de skiluri si cunoștințe de la echipa din Marea Britanie, arhitecți software in cloud, care sunt în prima linie a profesiei lor. În plus, sunt stabilite legături cu instituții academice din Iași.",
        },
        {
          heading: "Obiectiv 4",
          content:
            "Al patrulea obiectiv specific este maximizarea adoptării de capabilități SaaS in cloud  prin crearea unui set de instrumente, procese și expertiza pentru a stabili și a menține un ecosistem de parteneri care pot oferi serviciile create de noi prin proiect, sa fie valorificate de o gamă largă de organizații ținta, in mai multe domenii ale industriei, astfel susținând obiectivul programului de finanțare pentru integrarea pe verticala a soluțiilor IT.",
        },
        {
          heading: "Obiectiv 5",
          content:
            "Al cincilea obiectiv specific are ca scop asigurarea unei cifre de afaceri cuantificabila per client, prin trecerea la un model al veniturilor pe bază de anuitate unde creșterea viitoare se bazează pe veniturile de licență și este mult mai durabil decât crearea mai multor soluții tehnologice personalizate, cum facem acum, pentru care nu avem un cost previzionabil per client.",
        },
      ]}
    />

    <Grid container spacing={2}>
      <Grid item xs={12} className="ending">
        <p>
          Proiectul face parte din strategia de dezvoltare pe termen mediu a
          firmei si urmează unei faze de analiză a pieței, realizată prin
          centralizarea tuturor solicitărilor de dezvoltări software punctuale
          pe care firma le-a primit până acum. Aceste solicitări le prezentăm
          centralizate mai jos, pe categorii: Pana la momentul de fata,
          expertiza Appsbroker și tehnologia prezentată au fost solicitate
          într-un număr de proiecte de automatizare a proceselor de afaceri
          pentru clienți în sectoarele construcții auto, construcții, produse
          alimentare, de producție și de media precum si in cele financiare.
        </p>
      </Grid>
    </Grid>
  </Layout>
)

export default IndexPage
