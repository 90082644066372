import React from "react"
import PropTypes from 'prop-types';
import Accordion from "@material-ui/core/Accordion"
import AccordionDetails from "@material-ui/core/AccordionDetails"
import AccordionSummary from "@material-ui/core/AccordionSummary"
import "./Accordion.css"

export default class AbAccordion extends React.Component {

  state = {
    expanded: 'panel0',
  }

  handleChange = panel => (_, isExpanded) => {
    this.setState({ expanded: this.state.expanded !== panel ? panel : false });
  };

  renderPanel = (entry, index) => {
    return (
      <Accordion expanded={this.state.expanded === `panel${index}`} onClick={this.handleChange(`panel${index}`)} key={index}>
        <AccordionSummary
          aria-controls={`panel${index}bh-content`}
          id={`panel${index}bh-header`}
          className='header'
        >
          {entry.heading}
        </AccordionSummary>
        <AccordionDetails>
          <p className='innerText'>{entry.content}</p>
        </AccordionDetails>
      </Accordion>
    );
  }

  render() {
    return (
      <div className='root'>
        {this.props.entries.map(this.renderPanel)}
      </div>
    );
  }
}

AbAccordion.propTypes = {
  entries: PropTypes.arrayOf(
    PropTypes.shape({
      heading: PropTypes.string,
      content: PropTypes.string,
    })
  ),
}
